/*-------------------------------------------------------*/
/* Hero
/*-------------------------------------------------------*/
.hero {
  padding: 0;

  &__img {
    @include background-img-cover;
    min-height: 725px;

    @include bp-lg-down {
      min-height: 340px;
    }
  }

  &__item {
    padding: 0;
  }

  &__container {
    padding: 0;

    @media (max-width: 375px) {
      height: auto !important;
    }
  }

  &__text-holder {
    padding: 124px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;

    &--1 {
      padding: 40px 60px; 
      align-items: center;
      text-align: center;
      position: relative;
      z-index: 1;

      @media (max-width: 375px) {
        height: 100%;
      }
    }

    @include bp-lg-down {
      padding: 50px;
    }

    @include bp-sm-down {
      padding: 40px 20px 120px;
    }
  }

  &__title {
    line-height: 1.3;
    margin-bottom: 56px;
    font-size: 4.5rem;

    &--1 {
      color: #fff;
      font-size: 5.8rem;

      @media( max-width: 1439px ) {
        font-size: 5rem;
      }
    }

    &--boxed {
      max-width: 70%;

      @media( max-width: 1439px ) {
        max-width: 100%;
      }
    }

    @media( max-width: 1439px ) {
      font-size: 3.5rem;
    }

    @include bp-lg-down {
      font-size: 2rem;
      margin-bottom: 24px;
    }
  }

  &__subtitle {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 14px;
    text-transform: uppercase;
    letter-spacing: .5em;
    font-family: $body-font;
    color: $main-color;

    &--1 {
      color: #fff;
    }
  }

  &__socials {
    position: absolute;    
    z-index: 1;
    bottom: 50px;
    left: 50%;
    @include transform(translateX(-50%));

    @include bp-lg-up {
      top: 50%;
      left: auto;
      bottom: auto;
      right: 90px;
      @include transform(translateY(-50%));

      a {
        margin-right: 0;
        display: block;
      }
    }    
  }

  &__dot {
    color: $main-color;
  }

  .btn {
    justify-self: auto;
  }
}

.main-wrapper {
  overflow: hidden;
  position: relative;
}


/*-------------------------------------------------------*/
/* Service Boxes
/*-------------------------------------------------------*/
.service {  
  z-index: 1;  
  padding: 48px 40px;
  margin-bottom: 30px;
  display: block;
  background-color: #fff;

  &__icon {
    font-size: 4rem;
    line-height: 1;
    color: $main-color;
    display: inline-block;
    margin-bottom: 32px;
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 18px;
  }

  &__text {
    margin-bottom: 0;
    color: $text-color;
  }

  a {
    color: $text-color;

    &:hover {
      color: $main-color;
    }
  }

  &--1 {
    padding: 90px 40px 52px;
    margin-bottom: 0;
    @include background-img-cover;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      right: 0;
    }

    .service__text,
    .service__title {
      color: #fff;
      position: relative;
      z-index: 1;
    }
  }
}


/*-------------------------------------------------------*/
/* Testimonials
/*-------------------------------------------------------*/
.testimonial {  
  position: relative;

  @include bp-lg-up {
    max-width: 65%;
    margin-left: auto;
  }

  @media ( min-width: 1440px ) {
    max-width: 68%;
  }

  &__img-holder {
    @include background-img-cover;
    width: 100%;
    height: 400px;
    position: relative;    

    @include bp-lg-up {
      height: 360px;
      width: 360px;
      top: 72px;
      left: -288px;
      position: absolute;   
    }

    @media ( min-width: 1200px ) {
      width: 480px;
      height: 480px;
      left: -408px;
    }
  }

  &__info {
    background-color: $bg-dark;
    height: 100%;
    padding: 50px;
    
    @include bp-lg-up {
      padding: 124px 50px 124px 220px;
    }
  }

  &__info-container {

    @include bp-lg-up {
      max-width: 700px;
    }    
  }

  .section-title {
    color: #fff;
    margin-bottom: 56px;
  }

  &__author {
    font-size: 1.125rem;
    color: $main-color;
    display: block;

    @include bp-lg-up {
      margin-top: 60px;
    }    
  }

  &__company {
    color: #8B8F95;
    font-size: 0.9375rem;
  }

  &__text {
    font-size: 1.5rem;
    font-weight: 300;
    font-family: $heading-font;
    line-height: 1.5;
    margin-bottom: 14px;
    display: inline-block;
    position: relative;
    color: #fff;

    &:before {
      content: '\e802';
      font-size: 1.2rem;
      font-family: 'ui-icons';
      position: absolute;
      top: -34px;
      left: 0px;
      color: #fff;
      opacity: .4;

      @include bp-lg-up {
        top: 3px;
        left: -48px;
      }
    }
  }  
}


/*-------------------------------------------------------*/
/* Promo Section
/*-------------------------------------------------------*/
.promo {

  &__text-holder {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  &__title {
    margin-bottom: 24px;

    &--boxed {
      max-width: 470px;
    }
  }

  &__text {
    margin-bottom: 40px;
  }

  &__video {
    width: 100%;
  }

  &__img-1 {
    box-shadow: -5px 10px 25px rgba(8, 15, 26, 0.3);
  }

  &__img-2 {
    box-shadow: -30px 30px 40px rgba(22, 19, 62, 0.2);
    position: absolute;
    top: -74px;
    right: 30px;
  }

  @include bp-sm-down {
    &__img {
      width: 50%;
    }
  }  
}

.play-btn {
  display: inline-block;
  position: absolute;
  z-index: 3;
  width: 72px;
  height: 72px;
  border: 3px solid #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin: -10px 0 0 -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 16px;
    border-color: transparent transparent transparent #fff;
  }
}


/*-------------------------------------------------------*/
/* Call To Action
/*-------------------------------------------------------*/
.call-to-action {  
  position: relative;
  @include background-img-cover;

  &__title {
    font-size: 2.25rem;
    line-height: 1.4;
    margin-bottom: 16px;

    @include bp-sm-down {
      font-size: 1.5rem;
    }
  }

  &__text {
    margin-bottom: 32px;
    color: $heading-color;
  }
}


/*-------------------------------------------------------*/
/* Page Title
/*-------------------------------------------------------*/
.page-title {
  position: relative;
  width: 100%;
  overflow: hidden;  
  display: block;
  padding: 210px 0 180px;
  background-size: cover;
  @include background-img-cover;
  background-color: $bg-light;

  &__holder {
    max-width: 580px;
    position: relative;
  }

  &__title {
    line-height: 1.3;
  }

  &__subtitle {
    font-size: 20px;
    line-height: 1.4;
  }

  @include bp-lg-down {
    padding: 80px 0;
  }

  @include bp-md-down {
    padding: 40px 0;
  }
}


/*-------------------------------------------------------*/
/* Benefits
/*-------------------------------------------------------*/
.benefits {
  padding: 70px 48px;
  background-color: #fff;
  border-radius: 5px;

  &__item {
    @include display-flex;
    margin-bottom: 4px;
  }

  &__title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  &__item-title {
    font-size: 18px;
    color: $heading-color;
  }

  &__item-icon {
    color: #44D88D;
    font-size: 20px;
    margin-right: 8px;
    margin-top: -2px;
  }

  @include bp-sm-down {
    padding: 40px 32px;
  }
}


/*-------------------------------------------------------*/
/* Statistic
/*-------------------------------------------------------*/
.statistic {
  margin-bottom: 64px;

  @include bp-lg-up {
    padding-right: 24px;
    border-right: 1px solid rgba(#fff, .10);

    &-row > div:last-child .statistic {
      border-right: 0;
    }
  } 

  &__icon {
    color: $main-color;
    display: block;
    font-size: 4.5rem;
    line-height: 1;
    margin-bottom: 10px;
  }

  &__number {
    font-family: $heading-font;
    font-size: 2.6rem;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 14px;
  }

  &__title {
    font-size: 18px;
  }
}


/*-------------------------------------------------------*/
/* Team
/*-------------------------------------------------------*/
.team {
  margin-bottom: 32px;

  &__img {
    width: 100%;
  }

  &__body {
    background-color: #fff;
    padding: 40px;
  }

  &__name {
    font-size: 1.25rem;
    margin-bottom: 4px;
  }

  &__occupation {
    font-size: 0.9375rem;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 14px;
  }

  &__text {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}


/*-------------------------------------------------------*/
/* Newsletter
/*-------------------------------------------------------*/
.newsletter {
  
  .title-row {
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 21px;
    font-weight: 400;
  }

  &__form {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  .mc4wp-form-fields {
    @include display-flex;

    & > .form-group:first-child {
      flex: 1;
      margin-right: 8px;

      @include bp-sm-down {
        margin-right: 0;
      }
    }

    @include bp-sm-down {
      display: block;
    }
  }

  input {
    height: 50px;
  }

  input[type=email],
  input[type=text] {
    color: #fff;
    background-color: transparent;
    border-color: rgba(213, 217, 237, 0.36);

    &:focus {
      border-color: #fff;
    }
  }

  /* Change Color of Placeholders */
  input[type=email]::-webkit-input-placeholder,
  input[type=text]::-webkit-input-placeholder {
    color: #fff;
  }

  input[type=email]:-moz-placeholder,
  input[type=text]:-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  input[type=email]::-moz-placeholder,
  input[type=text]::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  input[type=email]:-ms-input-placeholder,
  input[type=text]:-ms-input-placeholder {
    color: #fff;
  }
}


/*-------------------------------------------------------*/
/* Pricing Tables
/*-------------------------------------------------------*/
.pricing {
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  padding: 40px 30px 45px;
  margin-bottom: 20px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: relative;
  display: flex;
  flex-direction: column;  
  @include transition ($all);

  @include bp-xl-up {
    padding: 40px 72px 45px;
  }

  @include bp-lg-up {
    height: 100%;
  }

  &--best {
    .pricing__price,
    .pricing__currency {
      color: $main-color;
    }    
  }
  
  &__title {
    font-size: 20px;
    margin-bottom: 7px;
  }

  &__price-box {
    padding: 0 0 18px;
  }

  &__price,
  &__currency {
    font-size: 2.875rem;
    font-weight: 700;
    font-family: $heading-font;
  }

  &__text {
    padding-bottom: 33px;
    border-bottom: 2px solid $border-color;
  }

  &__features {
    color: $text-color;
    text-align: left;
    margin-top: 29px;
    margin-bottom: 68px;
    list-style: none;
  }

  &__feature {
    display: flex;
  }

  &__check {
    margin-right: 10px;
    color: #52E27A;
  }

  &__button {
    margin-top: auto;
  }
}

.price-switcher {
  @include display-flex;
  justify-content: center;
  margin-bottom: 48px;

  &__button {
    background-color: #fff;
    width: 97px;
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 700;
    cursor: pointer;
    padding: 8px 17px;
    border-radius: 70px 0 0 70px;
    border: 0;
    box-shadow: 0px 3px 5px rgba(22, 35, 54, 0.1);
    @include transition($all);

    &:last-child {
      border-radius: 0 70px 70px 0; 
    }

    &--is-active,
    &:hover {
      background-color: $heading-color;
      border-color: transparent;
      color: #fff;
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }
}


/*-------------------------------------------------------*/
/* Moneyback
/*-------------------------------------------------------*/
.moneyback {
  padding: 72px 0;
  background-color: #F8FCFA;
  border-top: 1px solid #DFF7EA;
  border-bottom: 1px solid #DFF7EA;  

  &__container {
    text-align: center;
  }  

  &__img {
    display: inline-block;
    margin-bottom: 32px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 0;
  }

  &__accent {
    color: $main-color;
  }

  @include bp-md-up {
    &__container {
      display: flex;
      align-items: center;
      text-align: left;
    }

    &__img {
      margin-right: 40px;
      margin-bottom: 0;
    }
  }
}


/*-------------------------------------------------------*/
/* Process
/*-------------------------------------------------------*/
.process {
  margin-bottom: 50px;

  @include bp-lg-up {
    margin-bottom: 100px;

    &:nth-child(odd) {
      .process__info {
        margin-left: -40px;
        margin-right: auto;      
      }
    }

    &:nth-child(even) {
      .row > div:first-child {
        order: 2;
      }
    }

    &__info {
      margin-right: -40px;
      top: 40px;
    }
  }

  &__img {
    width: 100%;
  }  

  &__info {
    background-color: #fff;
    padding: 72px 60px 60px 100px;
    height: 100%;    
    position: relative;    
    z-index: 1;

    @include bp-sm-down {
      padding: 48px 40px 40px 40px;
    }
  }

  &__number {
    font-size: 2.25rem;
    font-family: $heading-font;
    float: left;
    margin-left: -40px;
    margin-top: -10px;
    line-height: 1;

    @include bp-sm-down {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 24px;
  }

  &__text {
    &:last-child {
      margin-bottom: 0;
    }
  }
}


/*-------------------------------------------------------*/
/* Contoct Form
/*-------------------------------------------------------*/
.contact-form {

  &-container {
    background-color: $bg-light;
    padding: 72px 48px;
  }
}

#contact-form .message {
  height: 50px;
  width: 100%;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  float: none;
  margin-top: 20px;
  display: none;
  color: #fff;
}

#contact-form .message.error { background-color: #f44336; }
#contact-form .message.success { background-color: #4CAF50; }



/*-------------------------------------------------------*/
/* Gmap
/*-------------------------------------------------------*/
.gmap {
  width: 100%;
  height: 350px;
}


/* Cookies banner
-------------------------------------------------------*/
.cc-container {
  @include display-flex;
  justify-content: center;
  padding: 0;
}

.cc-window {
  background-color: $heading-color !important;
  padding: 5px 20px !important;
}

.cc-message {
  margin-right: 20px;
  flex: 0 1 auto !important;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}

.cc-link {
  opacity: 1;
  font-weight: 700;

  &:hover,
  &:focus {
    color: #fff;
  }
}

a.cc-btn.cc-dismiss {
  background-color: $main-color;
  border-radius: 5px;
  height: 32px;
  line-height: 32px;
  padding: 0 14px;
  border: 0;
  color: #fff;
  min-width: auto !important;

  &:hover,
  &:focus {
    background-color: darken($main-color, 3%);
  }
}


/*-------------------------------------------------------*/
/* Contact Page
/*-------------------------------------------------------*/
.contact {
  border-radius: 5px;
  background-color: #fff;
  padding: 48px 110px;

  @include bp-lg-down {
    padding: 30px;
  }

  &__items {
    text-align: center;
    margin-bottom: 24px;
  }

  &__item {
    line-height: 26px;

    a {
      color: $text-color;

      &:hover {
        color: $main-color;
      }
    }
  }
}

/*-------------------------------------------------------*/
/* Icons Page
/*-------------------------------------------------------*/
.font-icons {
  & > div {
    text-align: center;
  }

  span {
    display: block;
    margin-top: 10px;
    margin-bottom: 32px;
  }

  i {
    font-size: 2.5rem;
  }
}


/*-------------------------------------------------------*/
/* 404 Page
/*-------------------------------------------------------*/
.error404 {
  padding: 120px 0;

  @include bp-lg-up {
    padding: 260px 0;
  }

  &__number {
    font-family: $heading-font;
    display: inline-block;
    font-weight: 700;
    font-size: 6.875rem;
    line-height: 1;

    @include bp-lg-down {
      margin-bottom: 48px;
      font-size: 5rem;
    }
  }

  &__title {
    font-size: 2.25rem;
  }
}