/*-------------------------------------------------------*/
/* Gutenberg Front-end Styles
/*-------------------------------------------------------*/

// Background color
.has-bittersweet-background-color { background-color: #fa6262; }
.has-blue-background-color { background-color: #2d7bfd; }
.has-violet-background-color { background-color: #ad3df3; }
.has-turquoise-background-color { background-color: #30dca5; }
.has-light-blue-background-color { background-color: #F3F2F5; }
.has-dark-background-color { background-color: #232329; }

// Text color
.has-bittersweet-color { color: #E12A21; }
.has-blue-color { color: #2d7bfd; }
.has-violet-color { color: #ad3df3; }
.has-turquoise-color { color: #30dca5; }
.has-light-blue-color { color: #F3F2F5; }
.has-dark-color { color: #232329; }


/* Buttons
-------------------------------------------------------*/
.wp-block-button .wp-block-button__link {
	padding: 14px 32px;
	font-size: 0.9375rem;
	border-radius: 5px;
	font-weight: 700;
	line-height: 1.5 !important;
}

.wp-block-button__link:not(.has-background),
.wp-block-button__link:not(.has-background):active,
.wp-block-button__link:not(.has-background):focus,
.wp-block-button__link:not(.has-background):hover {
	background-color: $main-color;
}


/* Full-width Images
-------------------------------------------------------*/
@include bp-lg-up {

	.fullwidth {
		.entry__article {
			.alignwide {
				margin-left: -120px;
				margin-right: -120px;
				width: auto;
			}

			.alignfull {
				margin-left: calc( 50% - 50vw );
				margin-right: calc( 50% - 50vw );
				max-width: 100vw;
				position: relative;
				z-index: 1;

				img {
					width: 100vw;
				}
			}
		}
	}
}


/* Images
-------------------------------------------------------*/
.wp-block-image {
	margin-top: 40px;
	margin-bottom: 40px;

	.alignleft {
		margin-right: 2em;
		max-width: 40%;

		@include bp-sm-down {
			float: none;
			margin-right: 0;
		}
	}

	.alignright {
		margin-left: 2em;
		max-width: 40%;

		@include bp-sm-down {
			float: none;
			margin-left: 0;
		}
	}
}


/* Tables
-------------------------------------------------------*/
.wp-block-table {
	width: 100%;
	margin-bottom: 2rem;
}


/* Latest Comments
-------------------------------------------------------*/
ol.wp-block-latest-comments {
	padding-left: 0;
}


/* Galleries
-------------------------------------------------------*/
ul.wp-block-gallery {
	list-style: none;
	padding-left: 0;
}

.wp-block-image figcaption {
	color: $meta-color;
}


/* Separators
-------------------------------------------------------*/
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $border-color;
}

.wp-block-separator.is-style-dots {
    background: 0 0;
    border: none;
    text-align: center;
    max-width: none;
    line-height: 1;
		height: auto;
		
		&::before {
			content: "\00b7 \00b7 \00b7";
			color: $meta-color;
			font-size: 1.25rem;
			font-weight: 700;
			letter-spacing: 2em;
			padding-left: 2em;
			font-family: serif;
		}
}

.wp-block-separator.is-style-default {
	max-width: 100px;
}

.wp-block-separator.is-style-dots:before {
	font-weight: 700;
}



/* Blockquote / Pullquote
-------------------------------------------------------*/
blockquote {
	margin: 0;
	line-height: 1.6;
	
	p {
		margin-bottom: 0;
	}

  cite {
		color: $meta-color;
		font-size: 0.875rem;
		margin-top: 1em;
		display: inline-block;
		font-style: normal;
  }
}

.wp-block-quote {
	padding: 24px;
	margin-bottom: 28px;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;

	&:before {
		content: '\e802';
		font-family: 'ui-icons';
		color: $secondary-color;
		opacity: 0.4;
	}

	p {
		font-size: 1.75rem;
		font-weight: 300;
		color: $heading-color;
		font-family: $heading-font;

		@include bp-lg-down {
			font-size: 1.4rem;
		}
	}

	&.is-large,
	&.is-style-large {
		padding: 40px;
		margin-top: 40px;
		margin-bottom: 40px;

		p {
			font-style: normal;
			position: relative;
		}

		cite,
		footer {
			font-size: 0.9375rem;
		}
	}
}

.wp-block-pullquote {
	padding: 0;
	text-align: left;
	line-height: 1.5;
	color: $heading-color;
	position: relative;

	blockquote {
		padding-left: 40px;

		&:before {
			content: '\e802';
			font-family: 'ui-icons';
			color: $secondary-color;
			opacity: 0.4;
			position: absolute;
			left: 0;
		}
	}

	p {
		font-family: $heading-font;
	}

	cite {
		opacity: .7;
		font-size: 0.9375rem;
		color: inherit;
	}

	@include bp-sm-up {
		&.alignleft,
		&.alignright {
			p {
				font-size: 1.25rem;
			}
		}

		&.alignleft {
			margin-right: 2em/*!rtl:end:ignore*/;
		}

		&.alignright {
			margin-left: 2em/*!rtl:end:ignore*/;
		}
	}

	@include bp-sm-down {
		&.alignleft,
		&.alignright {
			max-width: 100%;
			float: none;
			margin-left: 0;
			margin-right: 0;
		}
	}
}


// Solid Color
.wp-block-pullquote.is-style-solid-color {
	border: none;

	blockquote p {
		margin-bottom: 0;
	}

	&.alignleft blockquote p,
	&.alignright blockquote p {
		font-size: 1.25rem;
	}
}



// Gutenberg Styles (These styles are generated by WordPress now, so they can be deleted in WordPress version)

.wp-block-pullquote.is-style-solid-color blockquote {
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	max-width: 60%;
}

.wp-block-gallery {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
}

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
	margin: 0 16px 16px 0;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(even), .wp-block-gallery .blocks-gallery-item:nth-of-type(even) {
    margin-right: 0;
}

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
	width: calc((100% - 16px)/ 2);
}


@media (min-width: 600px) {
	.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item {
		width: calc((100% - 16px * 2)/ 3);
		margin-right: 16px;
	}
}



.wp-block-gallery .blocks-gallery-image:last-child, .wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
  margin: 0;
  height: 100%;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a, .wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a, .wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}


.wp-block-columns {
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 782px) {
.wp-block-columns {
    flex-wrap: nowrap;
}
}

@media (min-width: 600px) {
.wp-block-column:not(:last-child) {
    margin-right: 32px;
}
}

@media (min-width: 600px) {
.wp-block-column:nth-child(odd) {
    margin-right: 32px;
}
}

@media (min-width: 600px) {
.wp-block-column {
    flex-basis: 50%;
    flex-grow: 0;
}
}

@media (min-width: 600px) {
.wp-block-column:not(:first-child) {
    margin-left: 32px;
}
}

.wp-block-column {
    flex: 1;
    margin-bottom: 1em;
    flex-basis: 100%;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
}