/*-------------------------------------------------------*/
/* WordPress Styles
/*-------------------------------------------------------*/
.gallery-caption {
  display: block;
}

img {
  height: auto;
}


/* Alignment
-------------------------------------------------------*/
.alignleft,
.alignright {
	max-width: 100% !important;
	float: none;
}

@include bp-sm-up {
	.alignleft {
    float: left;
    margin: .5em 2em 2em 0;
	}

	.alignright {
    float: right;
    margin: .5em 0 2em 2em;
	}

	.alignleft,
	.alignright {
		max-width: 40% !important;
	}
}

@include bp-sm-down {
	margin-left: 0;
	margin-right: 0;
	float: none;
}

.alignnone {
  margin: .5em 2em;
}

.aligncenter {
  display: block;
  margin: .5em auto;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption,
.gallery-caption {
  max-width: 100%;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text,
.says {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
.bypostauthor > .comment-body > .comment-meta > .comment-author .avatar {
  border: 1px solid $border-color;
  padding: 2px;
}

/*-------------------------------------------------------*/
/* Password
/*-------------------------------------------------------*/
.post-password-form label > input {
  margin-bottom: 24px;
}

.post-password-form label + input {
  line-height: 41px;
  font-size: 15px;
  font-weight: 700;
  padding: 0 24px;
  font-family: $heading-font;
  border: none;
  background-color: $main-color;
  color: #fff;
  position: relative;
  top: -3px;
  width: auto;
}


/*-------------------------------------------------------*/
/* Sticky Post
/*-------------------------------------------------------*/
.post.sticky .entry__title:before {
  content: '\e801';
  font-family: "ui-icons";
  display: inline-block;
  margin-right: 12px;
  position: relative;
  top: -2px;
  font-size: 18px;
  color: $text-color;
  max-height: 20px;
}



/*-------------------------------------------------------*/
/* Article styles
/*-------------------------------------------------------*/

dt,
dd {
  padding: 10px 0;
  border-bottom: 1px solid $border-color;
}


/* Pagination */

// Post Multipages
.entry-pages {
  margin-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $border-color;

  a {
    min-width: 12px;
    display: inline-block;
    text-align: center;
  }
}

// Comments Multipages
.comment-navigation {
  margin: 20px 0;

  .nav-previous {
    float: left;
  }
  .nav-next {
    float: right;
  }
}

// Comment Form
.comment-respond {
  margin-top: 60px;
}