/*-------------------------------------------------------*/
/* Footer
/*-------------------------------------------------------*/
.footer {
  position: relative;
}


/* Footer Widgets
-------------------------------------------------------*/
.footer__widgets {
  padding: 100px 0 20px;

  @include bp-lg-down {
    padding: 72px 0 0;
  }

  a {
    color: $text-color;

    &:hover {
      color: $main-color;
    }
  } 
}


/* Bottom Footer
-------------------------------------------------------*/
.footer__bottom {
  padding: 48px 0;

  @include bp-lg-down {
    padding: 32px 0;
  }

  .widget_nav_menu {
    margin-bottom: 0;

    @include bp-lg-down {
      text-align: center;
      margin-bottom: 24px;
    }

    li {
      display: inline-block;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.copyright {
  display: inline-block;
  color: $text-color;
  text-align: right;

  a {
   color: inherit;

   &:hover,
   &:focus {
    color: $main-color;
   }
  }
}