// Colors
$text-color: #636A75;
$heading-color: #16133E;
$bg-light: #F7F9FA;
$bg-dark: #16133E;
$border-color: #E8ECED;
$border-color-dark: #313131;
$main-color: #F53B30;
$secondary-color: #4C86E7;
$meta-color: #8B8F95;


// Fonts
$body-font: 'Barlow', sans-serif;
$heading-font: 'Merriweather', serif;


// Navbar
$nav-height: 72px;