/*-------------------------------------------------------*/
/* Helper classes
/*-------------------------------------------------------*/
.clear { clear: both; }
.oh { overflow: hidden; }
.ov { overflow: visible; }
.relative { position: relative; }
.mobile section { background-attachment: scroll !important; }
.bg-dark { background-color: $bg-dark; }
.bg-light { background-color: $bg-light; }
.bg-white { background-color: #fff; }
.bg-color { background-color: $secondary-color; }
.white { color: #fff; }
.block { display: block; }
.left { float: left; }
.right { float: right; }
.valign { position: absolute; top: 50%; @include transform (translateY(-50%)); }
.img-full-width { width: 100%; }
.uppercase { text-transform: uppercase; letter-spacing: 0.02em; }
.last { margin-bottom: 0 !important; }


/* Section Dark / Background Image
-------------------------------------------------------*/
.bg-overlay,
.bg-dark,
.bg-img,
.bg-color,
.bg-color-overlay,
.bg-gradient {
  .section-title,
  .subtitle,
  .call-to-action__title,
  .statistic__number,
  .statistic__title,
  .page-title__title {
    color: #fff;
  }  
}


/* Box Shadow
-------------------------------------------------------*/
.box-shadow {
  box-shadow: 0px 15px 24px rgba(64, 90, 128, 0.05);
}

.box-shadow-large {
  box-shadow: 0 20px 40px rgba(61, 65, 84, 0.15);
}


/* Hovers
-------------------------------------------------------*/
.hover-up {
  top: 0;
  position: relative;
  @include transition($all);

  &:hover {
    box-shadow: 0 20px 40px rgba(61, 65, 84, 0.15);
    top: -2px;
  }  
}

.hover-down {
  @include transition($all);
  top: 0;
  position: relative;

  &:hover {
    box-shadow: 0px 5px 10px rgba(64, 90, 128, 0.05);
    top: 2px;
  }  
}

.hover-line {
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 3px;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: $main-color;
    @include transition($all);
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}


/* Offset
-------------------------------------------------------*/
.offset-top-100 {
  margin-top: -100px;
}


/* Gradients / Overlays
-------------------------------------------------------*/
.bg-white-gradient:before {
  content: "";
  top: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  @include linear-gradient-from-bottom(rgba(255,255,255,.5), #fff);
}

.bg-overlay {
  position: relative;

  &:before {
    content: "";
    top: 0;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 19, 62, 0.36);
  }

  &--white {
    &:before {
      background-color: rgba(#fff, 0.9);
    }
  }
}


/* Section Dividers
-------------------------------------------------------*/
.top-divider {
  border-top: 1px solid $border-color;
}

.bottom-divider {
  border-bottom: 1px solid $border-color;
}